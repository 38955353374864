/**
 * Allow exhaustive checking of case statements, by throwing an UnhandledCaseError
 * in the default branch.
 */
export class UnhandledCaseError extends Error {
    constructor(value, typeName) {
        super(`There was an unhandled case for "${typeName}": ${value}`);
        this.name = "UnhandledCaseError";
    }
}
/**
 * Ensure some T exists when the type systems knows it can be null or undefined.
 */
export function ensureExists(item, message = "The item did not exist when it was supposed to.") {
    if (item === null) {
        throw new Error(message);
    }
    if (item === undefined) {
        throw new Error(message);
    }
    return item;
}
/**
 * Fill an array with values.
 */
export function fill(size, fn) {
    const array = Array(size);
    for (let i = 0; i < size; i++) {
        array[i] = fn(i);
    }
    return array;
}
/**
 * Get a number in terms of Radians.
 */
export function rad(n) {
    return n * Math.PI;
}
export function addCSS(text) {
    const styleSheet = document.createElement("style");
    styleSheet.innerText = text;
    document.head.appendChild(styleSheet);
}
